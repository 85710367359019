import { Outlet, json, redirect, useLoaderData } from '@remix-run/react';
import { RequestList } from '~/components/request-list';
import { Card } from '~/components/ui/card';
import { Routes, withParams } from '~/constants/routes';
import { userPrefs } from '~/cookies.server';
import { withAugmentedContext } from '~/services/auth/Guards';

export const loader = withAugmentedContext(async ({ context }) => {
  if (context.user) {
    
    return redirect(Routes.Marketplace);
  }

  const requestableCategories =
    await context.brokkrApi.marketplace.getRequestableCategories();

  return json({
    requestableCategories,
  });
});

export const action = withAugmentedContext(async ({ request, context }) => {
  const formData = await request.formData();
  const searchValue = formData.get('searchValue') as string;
  const cookieHeader = request.headers.get('Cookie');
  const cookie = (await userPrefs.parse(cookieHeader)) || {};

  if (cookie.userEmail) {
    await context.brokkrApi.leads.createHubSpotLead({
      email: cookie.userEmail,
      note: `The customer has requested: ${searchValue}`,
      inquiredCategory: searchValue,
    });

    return json({ status: 200 });
  }

  return redirect(
    withParams(Routes.PublicMarketplaceRequestDevice, {
      searchValue,
    }),
  );
});

export default function PublicMarketplace() {
  const { requestableCategories } = useLoaderData<typeof loader>();

  return (
    <Card className="flex flex-col p-4 border-none gap-7 sm:p-10">
      <RequestList {...requestableCategories} />
      <Outlet />
    </Card>
  );
}
